<template>
  <a :href="link" class="button" :target="external ? '_blank' : ''">
    {{ text }} <ExternalLinkIcon class="w-4 ml-3" />
  </a>
</template>

<script>
import ExternalLinkIcon from '@/components/svg/icons/ExternalLinkIcon'

export default {
  name: 'Button',
  components: { ExternalLinkIcon },
  props: {
    text: { type: String, required: true },
    link: { type: String, required: true },
    external: { type: Boolean, default: false }
  }
}
</script>

<style lang="postcss" scoped>
.button {
  @apply flex items-center border border-white py-3 px-14 bg-transparent;
  @apply text-white text-2xl font-light lowercase;
  @apply transition-all duration-200 ease-linear;
  @apply hover:bg-white hover:text-brand;
}
</style>
