<template>
  <main v-if="content" class="products">
    <Hero :image="content.heroImage.url" :text="content.heroTitle" />

    <h2 class="title">{{ content.productTitle }}</h2>

    <h3 class="subtitle">{{ content.productText }}</h3>

    <ProductCards :products="products" />

    <FullImage
      :buttonLink="content.link"
      :buttonText="content.linkButtonText"
      :image="content.linkImage.url"
      :text="content.linkText"
      :title="content.linkTitle"
    />
  </main>
</template>

<script>
import Hero from '@/components/layout/Hero'
import ProductCards from '@/components/ProductCards'
import FullImage from '@/components/layout/FullImage'

export default {
  name: 'ProductsView',
  components: { Hero, ProductCards, FullImage },
  props: {
    content: {
      type: Object,
      required: true
    },
    products: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="postcss" scoped>
.products {
  @apply flex flex-col items-center;
}
.title,
.subtitle {
  @apply mx-auto max-w-screen-xl px-5 text-4xl text-brand text-center;
}
.title {
  @apply mt-12 font-bold
}
.subtitle {
  @apply mb-12;
}
.products >>> .product-cards {
  @apply mb-12;
}
</style>
