<template>
  <section
    :class="{ isEquipment: view === 'equipment' && screen > 1000}"
    :style="{ backgroundImage: `url(${image})`}"
    class="hero"
  >
    <h1>{{ text }}</h1>
  </section>
</template>

<script>
export default {
  name: 'Hero',
  props: {
    image: {
      type: String,
      required: false
    },
    text: {
      type: String,
      required: true
    },
    view: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      screen: ''
    }
  },
  created () {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  destroyed () {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize () {
      this.screen = window.innerHeight
    }
  }
}
</script>

<style lang="postcss" scoped>
  .hero {
    @apply flex justify-center items-center w-full bg-brand bg-no-repeat bg-cover bg-center;
    height: calc(100vh - 85px);
  }
  .hero.isEquipment {
    max-height: calc(100vh - 232px - 85px);
  }
  .hero h1 {
    @apply max-w-screen-xl p-5 text-4xl text-white text-center font-bold uppercase;
    @apply md:text-6xl;
  }
</style>
