<template>
  <section :style="{ backgroundImage: `url(${image})`}" class="full-image">
    <h1 class="title">{{ title }}</h1>
    <p class="text">{{ text }}</p>
    <ButtonLink v-if="buttonLink && buttonText" :link="buttonLink" :text="buttonText" white external />
  </section>
</template>

<script>
import ButtonLink from '@/components/ButtonLink'

export default {
  name: 'FullImage',
  components: { ButtonLink },
  props: {
    image: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    },
    buttonText: {
      type: String,
      required: false
    },
    buttonLink: {
      type: String,
      required: false
    }
  },
  computed: {
    backgroundImage () {
      return `${this.image}.jpg`
    }
  }
}
</script>

<style lang="postcss" scoped>
.full-image {
  @apply flex flex-col justify-center items-center w-full py-32 px-12 bg-brand bg-center bg-no-repeat bg-cover;
}
.title,
.text {
  @apply w-full text-white text-lg text-center;
  @apply md:text-4xl;
  max-width: 580px;
}
.title {
  @apply font-bold;
}
.text {
  @apply mb-8 font-light;
}
</style>
