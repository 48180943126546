<template>
  <article class="product">
    <figure class="image">
      <img :src="product.image" :alt="product.name" />
    </figure>

    <h1 class="name">{{ product.name }}</h1>

    <Button text="saber más" small @click.native="$router.push({ path: product.path })" />
  </article>
</template>

<script>
import Button from '@/components/Button'

export default {
  name: 'ProductCard',
  components: { Button },
  props: {
    product: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="postcss" scoped>
.product {
  @apply flex flex-col items-center w-full border border-brand p-5;
}
.image {
  @apply w-full mb-5;
}
.image img {
  @apply object-contain w-full;
}
.name {
  @apply flex-1 my-5 px-5 text-brand text-2xl md:text-4xl text-center font-bold uppercase;
}
</style>
