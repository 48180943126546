<template>
  <section class="product-cards">
    <ProductCard
      v-for="(product, index) in products"
      :key="index"
      :product="product"
    />
  </section>
</template>

<script>
import ProductCard from '@/components/ProductCard'
export default {
  name: 'ProductCards',
  components: { ProductCard },
  props: {
    products: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="postcss" scoped>
.product-cards {
  @apply flex flex-wrap justify-between items-stretch mx-auto w-full max-w-screen-xl;
}
.product-cards >>> .product {
  @apply flex-1 m-2;
}
</style>
